import request from "./request";

/**
 * 客户
 */

/**
 * 查询
 */
export const list = data => request.get("/c/", { params: data });

/**
 * 改备注名
 */
export const updateName = (id, name) =>
  request.post("/c/updateName", {
    id,
    name
  });

/**
 * 改标签
 */
export const updateTag = (ids, tagid) =>
  request.post("/c/updateTag", {
    ids,
    tagid
  });

/**
 * 发放优惠券
 */
export const sendCoupon = data => request.post("/c/sendCoupon", data);

/**
 * 查询规则
 */
export const getRule = () => request.get("/c/rule");

/**
 * 编辑规则
 */
export const updateRule = data => request.post("/c/rule", data);
