<template>
  <Layout>
    <div class="app-userlist">
      <a-page-header title="顾客管理">
        <template slot="extra">
          <router-link :to="{ name: 'Tag' }">
            <a-icon type="crown" />
            会员等级
          </router-link>
        </template>
      </a-page-header>

      <div class="tool">
        <a-form layout="inline">
          <a-button
            :disabled="selectedRowKeys.length > 0 ? false : true"
            @click="showTag"
            type="primary"
          >
            <a-icon type="crown" /> 设置等级
          </a-button>

          <a-button
            style="margin-left:10px"
            :disabled="selectedRowKeys.length > 0 ? false : true"
            @click="showCoupon"
            type="primary"
          >
            <a-icon type="red-envelope" /> 发放优惠券
          </a-button>
          <router-link style="margin-left:10px" :to="{ name: 'ClientCoupon' }">
            发券记录
          </router-link>
        </a-form>

        <a-form layout="inline" :form="form" @submit="handleSearch">
          <a-form-item label="会员等级">
            <a-select
              allow-clear
              style="min-width:180px"
              placeholder="请选择"
              v-decorator="['tagid']"
            >
              <a-select-option v-for="t in tagList" :key="t.id" :value="t.id"
                >{{ t.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-input
              v-decorator="['nickName']"
              :maxLength="20"
              placeholder="用户昵称"
            >
              <a-icon slot="prefix" type="user" style="color:rgba(0,0,0,.25)" />
            </a-input>
          </a-form-item>
          <a-form-item>
            <a-input
              v-decorator="['name']"
              :maxLength="20"
              placeholder="备注"
            />
          </a-form-item>

          <a-form-item>
            <a-button type="primary" html-type="submit">
              搜索
            </a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="table-wrap">
        <a-table
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange
          }"
          :columns="columns"
          :data-source="list"
          :pagination="pagination"
          :loading="loading"
          @change="handleTableChange"
        >
          <span slot="avatarUrl" slot-scope="img">
            <img :src="img" class="avatar" />
          </span>
          <template slot="name" slot-scope="name, record">
            <editable-cell
              :text="name"
              @change="updateName(record.id, $event)"
            />
          </template>
          <span slot="createdAt" slot-scope="time">{{
            time | formatDate
          }}</span>
        </a-table>
      </div>
    </div>
    <!-- 会员等级 -->
    <a-modal v-model="tag.show" title="设置会员等级" @ok="updateTag">
      <a-form-model>
        <a-form-model-item :label="`当前选中 ${selectedRowKeys.length} 个顾客`">
          <a-select v-model="tag.tagId" placeholder="请选择">
            <a-select-option v-for="t in tagList" :key="t.id" :value="t.id"
              >{{ t.name }}
            </a-select-option>
            <a-select-option :value="0">无等级</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <!-- 发券 -->
    <a-modal v-model="send.show" title="发放优惠券" @ok="sendCoupon">
      <a-form-model>
        <a-form-model-item :label="`当前选中 ${selectedRowKeys.length} 个顾客`">
          <a-select v-model="send.couponId" placeholder="请选择">
            <a-select-option v-for="c in couponList" :key="c.id" :value="c.id"
              >{{ c.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </Layout>
</template>

<script>
import Layout from "@/layout/index.vue";

import EditableCell from "@/components/editable-cell.vue";

import loadCouponMixin from "@/mixins/loadCoupon";
import couponMixin from "@/mixins/coupon";

const ClientSvc = require("@/service/client");
const TagSvc = require("@/service/tag");

export default {
  name: "UserList",

  data() {
    return {
      form: this.$form.createForm(this, { name: "form_search" }),

      list: [],
      filters: {},
      pagination: {
        showTotal: total => `共 ${total} 条记录`,
        showSizeChanger: true,
        pageSize: 10,
        pageSizeOptions: ["10", "20", "30", "40", "50", "100"]
      },
      loading: false,

      tagList: [],

      selectedRowKeys: [],

      // 设置会员等级
      tag: {
        show: false,
        tagId: null
      },

      // 发放优惠券
      send: {
        show: false,
        couponId: null
      }
    };
  },

  computed: {
    columns() {
      const { filters } = this;

      return [
        {
          title: "头像",
          dataIndex: "avatarUrl",
          key: "avatarUrl",
          width: 60,
          align: "center",
          scopedSlots: { customRender: "avatarUrl" }
        },
        {
          title: "昵称",
          dataIndex: "nickName",
          key: "nickName",
          width: 160,
          filteredValue: filters.nickName || null
        },
        {
          title: "备注",
          dataIndex: "name",
          key: "name",
          scopedSlots: { customRender: "name" },
          filteredValue: filters.name || null
        },
        {
          title: "会员等级",
          dataIndex: "tagName",
          key: "tagName",
          width: 180
        },
        {
          title: "积分",
          dataIndex: "point",
          key: "point",
          width: 100,
          align: "right"
        },
        {
          title: "性别",
          dataIndex: "gender",
          key: "gender",
          width: 60,
          align: "center",
          customRender: text => {
            const GENDER = ["未知", "男", "女"];
            return GENDER[text];
          }
        },
        {
          title: "城市",
          dataIndex: "city",
          key: "city",
          width: 160,
          customRender: (city, row) => {
            if (!city) return "未知";

            let txt = `${row.province}${row.city}`;
            if (row.country != "中国") {
              txt += `, ${row.country}`;
            }
            return txt;
          }
        },
        {
          title: "注册时间",
          dataIndex: "createdAt",
          key: "createdAt",
          width: 180,
          scopedSlots: { customRender: "createdAt" }
        }
      ];
    }
  },

  mixins: [loadCouponMixin, couponMixin],

  methods: {
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },

    // 发送优惠券
    showCoupon() {
      if (this.selectedRowKeys.length <= 0) {
        return;
      }

      this.send.couponId = null;
      this.send.show = true;
    },
    async sendCoupon() {
      if (this.selectedRowKeys.length <= 0) {
        return;
      }

      const { couponId } = this.send;
      if (!couponId) {
        return;
      }

      const ids = this.selectedRowKeys
        .map(idx => this.list[idx])
        .map(x => x.id);

      try {
        const { type, name, num1, num2 } = this.couponList.filter(
          x => x.id === couponId
        )[0];
        await ClientSvc.sendCoupon({ ids, couponId, type, name, num1, num2 });

        this.selectedRowKeys = [];
        this.send = {
          show: false
        };
        this.$message.info("发放优惠券成功");
      } catch (err) {
        console.error(err);
        this.$message.error("发放优惠券失败，错误：" + err.message);
      }
    },

    async updateName(id, name) {
      try {
        await ClientSvc.updateName(id, name);

        const idx = this.list.findIndex(x => x.id == id);
        this.$set(this.list, idx, {
          ...this.list[idx],
          name
        });
      } catch (err) {
        console.error(err);
        this.$message.error("修改备注失败，错误：" + err.message);
      }
    },

    // 设置会员等级
    showTag() {
      if (this.selectedRowKeys.length <= 0) {
        return;
      }

      // 是否所有都一个等级
      const tagIds = window._.uniq(
        this.selectedRowKeys.map(idx => this.list[idx]).map(x => x.tagid)
      );
      this.tag.tagId = tagIds.length === 1 ? tagIds[0] : null;
      this.tag.show = true;
    },
    async updateTag() {
      if (this.selectedRowKeys.length <= 0) {
        return;
      }

      const { tagId } = this.tag;

      const ids = this.selectedRowKeys
        .map(idx => this.list[idx])
        .map(x => x.id);

      try {
        await ClientSvc.updateTag(ids, tagId || 0);

        this.selectedRowKeys = [];

        await this.fetch(this.filters);

        this.tag.show = false;
      } catch (err) {
        console.error(err);
        this.$message.error("修改等级失败，错误：" + err.message);
      }
    },

    async handleSearch(e) {
      e.preventDefault();
      const values = this.form.getFieldsValue();
      let { nickName, name, tagid } = values;

      nickName = nickName || "";
      name = name || "";
      this.filters = { ...this.filters, nickName, name, tagid };

      await this.fetch(this.filters);
    },

    async handleTableChange(pagination, filters) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      this.filters = filters;

      const { pageSize, current: page } = pagination;
      await this.fetch({
        pageSize,
        page,
        ...filters
      });
    },

    async fetch(params = {}) {
      this.loading = true;

      try {
        const data = await ClientSvc.list({
          page: 1,
          pageSize: 10,
          ...params
        });

        const pagination = {
          ...this.pagination,
          total: data.cnt,
          current: data.page,
          pageSize: data.pageSize
        };
        this.list = data.res;
        this.pagination = pagination;

        this.loading = false;
      } catch (err) {
        this.loading = false;

        console.error(err);
        this.$message.error("加载数据出错，错误：" + err.message);
      }
    }
  },

  async mounted() {
    try {
      const res = await TagSvc.list();
      this.tagList = res;
    } catch (err) {
      console.error(err);
      this.$message.error("加载等级出错，错误：" + err.message);
    }

    await this.fetch();
  },

  components: {
    Layout,
    EditableCell
  }
};
</script>

<style lang="scss">
.app-userlist {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--header-height));
  background: #f0f2f5;
  padding: 24px 24px 0;

  .tool {
    padding: 24px 24px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .table-wrap {
    background: #fff;

    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 5px;
      overflow: hidden;
      border: 1px solid #fff;
    }

    .ant-table-pagination.ant-pagination {
      float: none;
      text-align: center;
    }
  }
}
</style>
