import request from "./request";

/**
 * 标签
 */

/**
 * 查询
 */
export const list = () => request.get("/tag/");

/**
 * 添加/编辑
 */
export const edit = data => request.post("/tag/edit", data);
